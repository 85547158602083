import * as React from "react";
import {AppStore} from "../../stores/appStore";
import { observer } from "mobx-react";
import "./events.scss";

interface IProps {
    appStore: AppStore;
}

export const Events: React.FC<IProps> = observer(({ appStore }: IProps) => {
    const { height } = appStore.viewportDimensions;

    return (
        <div className={"events"} style={{ height }}>
            <div>
                <h3>Upcoming events</h3>
                <ul>
                    <li>🎂 Aug 24: Ethan Bond's birthday</li>
                </ul>
            </div>
            <p className={"editors-note"}>
                Sorry if I missed anyone! In the future, this section will include birthdays, concerts/events people
                are planning to go to, travel plans, and other useful info.
            </p>
        </div>
    );
});
