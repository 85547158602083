import React from "react";
import {observer, useLocalStore} from "mobx-react";
import {SurveyStep, SurveyStore} from "../stores/surveyStore";
import {Steps} from "antd";
import {ContributionForm} from "../components/contributionForm/contributionForm";
import {Introduction} from "../components/introduction/introduction";
import {RegistrationForm} from "../components/registrationForm/registrationForm";
import styles from "./survey.module.scss";
import {SubmissionThankYou} from "../components/thankYou/submissionThankYou";
import {RouteComponentProps } from "react-router-dom";
import {AppStore} from "../stores/appStore";
import {Helmet} from "react-helmet";

interface IUrlParams {
    circleId: string;
    editionId: string;
    surveyToken: string;
}

interface IProps extends RouteComponentProps<IUrlParams> {
    appStore: AppStore;
}

export const Survey: React.FC<IProps> = observer(({ appStore, match }: IProps) => {
    const { circleId, editionId, surveyToken } = match.params;
    const { height } = appStore.viewportDimensions;

    const store = useLocalStore(() => new SurveyStore(appStore, circleId, editionId, surveyToken));

    const renderContent = (step: SurveyStep) => {
        switch (step) {
            case SurveyStep.Introduction:
                return <Introduction surveyStore={store} />;
            case SurveyStep.Registration:
                return <RegistrationForm surveyStore={store} />;
            case SurveyStep.Contribution:
                return <ContributionForm store={store.responseFormStore} surveyStore={store}/>;
            case SurveyStep.ThankYou:
                return <SubmissionThankYou />;
            default:
                return "Error";
        }
    };

    return (
        <div className={styles.survey} style={{ height }}>
            <Helmet>
                <title>Contribute | slowpost</title>
            </Helmet>
            {store.step !== SurveyStep.ThankYou &&
                <Steps current={store.step} size={"small"} className={styles.steps} labelPlacement={"vertical"}>
                    <Steps.Step key={"introduction"} title={"Introduction"}/>
                    <Steps.Step key={"basic-info"} title={"Basic info"}/>
                    <Steps.Step key={"contribution"} title={"Contribution"}/>
                </Steps>
            }
            {renderContent(store.step)}
        </div>
    );
});