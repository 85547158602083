import * as React from "react";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import styles from "../../views/edition.module.scss";

interface IProps { }

export const Rating: React.FC<IProps> = () => {
    const [ rating, setRating ] = React.useState<number>(-1);
    const ratings = [1, 2, 3, 4, 5];

    const handleSetRating = (r: number) => () => {
        setRating(r);
    };

    return (
        <div className={styles.rating}>
            <h4>{rating === -1 ? "How much did you enjoy this edition?" : "Thank you!"}</h4>
            {ratings.map(r => {
                return (
                    rating <= r - 1
                        ? <StarOutlined style={{ marginRight: "10px" }} onClick={handleSetRating(r)} />
                        : <StarFilled style={{ marginRight: "10px" }} onClick={handleSetRating(r)}/>
                );
            })}
        </div>
    );
};
