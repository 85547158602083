import { AppStore, IMAGE_URL_ROOT } from "./appStore";
import { ApiStore } from "./apiStore";
import { action, computed, observable } from "mobx";
import {
    IEditionPreview,
    ISurveyQuestionAndResponses,
    ISurveyQuestionResponder,
    ISurveyQuestionResponse,
} from "../types";

export interface IUniqueContributor
    extends Omit<Omit<Omit<ISurveyQuestionResponder, "created_at">, "updated_at">, "id"> {}

function responderToUniqueContributor(responder: ISurveyQuestionResponder): IUniqueContributor {
    const { phone, name, location, email } = responder;
    return {
        phone,
        name,
        location,
        email,
    };
}

export class EditionStore {
    @observable public appStore: AppStore;
    @observable public isLoading: boolean;
    @observable public edition: IEditionPreview | undefined;

    public circleId: number;
    public editionId: number;
    public newsletterToken: string | undefined;

    private apiStore: ApiStore;

    constructor(appStore: AppStore, circleId: number, editionId: number, newsletterToken?: string) {
        this.appStore = appStore;
        this.circleId = circleId;
        this.editionId = editionId;
        this.apiStore = appStore.apiStore;
        this.isLoading = true;
        this.newsletterToken = newsletterToken;

        if (!this.newsletterToken) {
            this.getEditionPreview();
        } else {
            this.getEdition();
        }
    }

    private preloadImageResponses() {
        if (!this.edition) {
            return;
        }

        this.edition.survey.survey_questions.forEach((q: ISurveyQuestionAndResponses) => {
            if (q.supports_attachments) {
                q.survey_question_responses.forEach((a: ISurveyQuestionResponse) => {
                    if (!a.image_url || a.hidden || !a.response || a.response === "") {
                        return;
                    }
                    const cacheImage = new Image();
                    cacheImage.src = `${IMAGE_URL_ROOT}${a.image_url}`;
                });
            }
        });
    }

    @action.bound
    public getEditionPreview = async () => {
        console.log("Getting preview");
        this.isLoading = true;
        const edition = await this.apiStore.getEditionPreview(this.circleId, this.editionId);
        const json = await edition.json();
        console.log("Received json", json);
        this.edition = json;
        this.preloadImageResponses();
        this.isLoading = false;
    };

    @action.bound
    public getEdition = async () => {
        if (!this.newsletterToken) {
            throw new Error("Attempted to fetch published edition without edition token");
        }

        this.isLoading = true;
        const edition = await this.apiStore.getEdition(this.circleId, this.editionId, this.newsletterToken);
        const json = await edition.json();
        this.edition = json;
        this.preloadImageResponses();
        this.isLoading = false;
    };

    @computed
    public get uniqueContributors() {
        const uniqueContributors = new Map<string, IUniqueContributor>();
        if (!this.edition) {
            return uniqueContributors;
        }

        this.edition.survey.survey_questions.forEach((q) => {
            q.survey_question_responses
                .filter((r) => !r.hidden && r.response !== "")
                .forEach((r) => {
                    uniqueContributors.set(r.survey_responder.phone, responderToUniqueContributor(r.survey_responder));
                });
        });

        return uniqueContributors;
    }

    @computed
    public get numContributors() {
        return this.uniqueContributors.size;
    }
}
