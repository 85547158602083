import React from "react";
import { Breadcrumb, Button, Input, Popover, Tabs } from "antd";
import { EditionEditorStore } from "../stores/editionEditorStore";
import { observer, useLocalStore } from "mobx-react";
import { Link, RouteComponentProps } from "react-router-dom";
import { AppStore } from "../stores/appStore";
import { Responses } from "./responses";
import styles from "./editionEditor.module.scss";
import { SurveyEditor } from "./surveyEditor";
import { EllipsisOutlined, SendOutlined } from "@ant-design/icons";
import { Newsletter } from "./newsletter";

interface IUrlParams {
    circleId: string;
    editionId: string;
}

interface IProps extends RouteComponentProps<IUrlParams> {
    appStore: AppStore;
}

interface ITokenPublicationProps {
    root: string;
    token: string | undefined;
    onPublish: () => void;
}

const TokenPublication: React.FC<ITokenPublicationProps> = ({ root, token, onPublish }: ITokenPublicationProps) => {
    return (
        <div className={styles.tokenPublication}>
            <input value={token === undefined ? "Unpublished" : `${root}/${token}`} />
            <Button type={"primary"} icon={<SendOutlined />} onClick={onPublish}>
                Publish
            </Button>
        </div>
    );
};

export const EditionEditor: React.FC<IProps> = observer(({ match, appStore }: IProps) => {
    const { circleId, editionId } = match.params;
    const store = useLocalStore(() => new EditionEditorStore(appStore, circleId, editionId));

    const handleSetTitle = (e: React.FormEvent<HTMLInputElement>) => {
        store.setTitle(e.currentTarget.value);
    };

    const handlePublishEdition = async () => {
        await store.publishNewsletter();
    };

    return (
        <div className={"edition-editor"}>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to={"../../"}>Curator</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={"../../"}>Your circles</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={"../"}>{store.circle ? store.circle.name : "Loading..."}</Link>
                </Breadcrumb.Item>
            </Breadcrumb>
            <div className={styles.titleBar}>
                <h1 className={styles.pageTitle}>{store.title}</h1>

                <Popover content={<Input placeholder={"Add a title"} value={store.title} onChange={handleSetTitle} />}>
                    <Button style={{ marginLeft: "1rem" }} icon={<EllipsisOutlined />} />
                </Popover>
            </div>

            <Tabs defaultActiveKey={"survey"}>
                <Tabs.TabPane key={"survey"} tab={"Survey"}>
                    <div className={styles.pageContent}>
                        <TokenPublication
                            root={`${window.location.host}/survey/${circleId}/${editionId}`}
                            token={store.surveyToken}
                            onPublish={console.log}
                        />
                        <SurveyEditor questions={store.questions} editionEditorStore={store} />
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane key={"responses"} tab={"Responses"}>
                    <div className={styles.pageContent}>
                        <Responses
                            appStore={appStore}
                            surveyToken={store.surveyToken}
                            circleId={circleId}
                            editionId={editionId}
                        />
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane key={"newsletter"} tab={"Newsletter"}>
                    <div className={styles.pageContent}>
                        <TokenPublication
                            root={`${window.location.host}/circles/${circleId}/editions/${editionId}/view/`}
                            token={store.newsletterToken}
                            onPublish={handlePublishEdition}
                        />

                        <div className={styles.newsletterPreview}>
                            <Newsletter
                                appStore={appStore}
                                circleId={parseInt(circleId)}
                                editionId={parseInt(editionId)}
                                newsletterToken={undefined}
                            />
                        </div>
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
});
