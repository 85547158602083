import React, { useState } from "react";
import {Input, Button, message} from "antd";
import { observer } from "mobx-react";
import { Link, Redirect } from "react-router-dom";
import { AppStore } from "../stores/appStore";
import styles from "./login.module.scss";
import { Logo } from "../common/logo";
import { COLORS } from "../common/utils";
import { Spinner } from "../components/loading/loading";

interface IProps {
    appStore: AppStore;
}

export const Login: React.FC<IProps> = observer(({ appStore }: IProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    if (appStore.currentUser != null) {
        console.log("Already signed in, redirecting");
        return <Redirect to={"/curator"} />;
    }

    const handleChange = (handler: (value: string) => void) => (e: React.FormEvent<HTMLInputElement>) => {
        handler(e.currentTarget.value);
    };

    const attemptSignIn = async () => {
        setIsLoading(true);
        const response = await appStore.signInUser({ email, password });
        if (!response.okay) {
            message.error(response.message);
        }
        setIsLoading(false);
    };

    return (
        <div className={styles.login}>
            <Logo width={"300px"} color={COLORS.brandColor} style={{ marginBottom: "3rem" }} />

            {isLoading ? (
                <div className={styles.loginPanel}>
                    <Spinner text={"Signing you in..."} />
                </div>
            ) : (
                <div className={styles.loginPanel}>
                    <Input
                        className={styles.input}
                        onChange={handleChange(setEmail)}
                        value={email}
                        placeholder={"your@email.com"}
                    />
                    <Input
                        className={styles.input}
                        onChange={handleChange(setPassword)}
                        value={password}
                        placeholder={"hunter2"}
                        type={"password"}
                    />
                    <Button type={"primary"} size={"large"} className={"btn-fill"} onClick={attemptSignIn}>
                        Sign in
                    </Button>
                    <Link to={"/register"}>
                        Don't have an account? <a href={"/register"}>Register!</a>
                    </Link>
                </div>
            )}
        </div>
    );
});
