import * as React from "react";
import { Conversation } from "@streamlinehq/streamline-bold/lib/messages-chat-smileys";
import StreamlineIcon from "@streamlinehq/streamline-icons-react";
import { ISurveyQuestionResponder, ISurveyQuestionResponse } from "../../types";
import styles from "../../views/edition.module.scss";
import { IMAGE_URL_ROOT } from "../../stores/appStore";

interface IProps {
    answer: ISurveyQuestionResponse;
}

interface IFeedbackActionsProps {
    author: ISurveyQuestionResponder;
    answerText: string;
}

export const FeedbackActions: React.FC<IFeedbackActionsProps> = ({ answerText, author }: IFeedbackActionsProps) => {
    const numWordsInPreview = 8;
    const splitAnswer = answerText.split(" ");
    const selectedWords = splitAnswer.slice(0, Math.min(numWordsInPreview, splitAnswer.length - 1)).join(" ");

    const discussionText = `I just saw your slowpost answer, "${selectedWords}..." and wanted to say...`;

    const createHref = (content: string) => {
        return `sms:${author.phone}&body=${escape(content)}`;
    };

    return (
        <div className={styles.feedbackActions}>
            <a href={createHref(discussionText)} className={styles.feedbackAction}>
                <StreamlineIcon fill={"#43BF4D"} icon={Conversation.ConversationTextAlternate} />
            </a>
        </div>
    );
};

export const Answer = React.forwardRef(({ answer }: IProps, ref: any) => {
    const { survey_responder, response } = answer;

    const maybeRenderImage = answer.image_url ? (
        <img alt={"Uploaded"} className={styles.image} src={`${IMAGE_URL_ROOT}${answer.image_url}`} />
    ) : undefined;

    return (
        <div className={styles.answer}>
            <div className={styles.answerContent} ref={ref}>
                {maybeRenderImage}
                {response}
            </div>

            <div className={styles.answerAuthor}>
                <div className={styles.answerScrollMask} />
                <div>{survey_responder.name}</div>
                <FeedbackActions author={survey_responder} answerText={response} />
            </div>
        </div>
    );
});
