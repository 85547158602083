import * as React from "react";
import { useState } from "react";
import { Author } from "../author/author";
import { EyeTwoTone, StarOutlined } from "@ant-design/icons";
import { EyeInvisibleTwoTone, StarTwoTone } from "@ant-design/icons/lib";
import { Button } from "antd";
import { Column, Row } from "../../common/layout";
import { ISurveyQuestionAndResponses, ISurveyQuestionResponse } from "../../types";
import styles from "./questionAndResponses.module.scss";

const cn = require("classnames");

interface IProps {
    imageRootUrl: string;
    data: ISurveyQuestionAndResponses;
    onHide: (selectedResponseIds: Set<number>) => void;
    onShow: (selectedResponseIds: Set<number>) => void;
    onHighlight: (selectedResponseIds: Set<number>) => void;
}

export const QuestionAndResponses: React.FC<IProps> = ({ imageRootUrl, data, onHide, onHighlight, onShow }: IProps) => {
    const [checkedResponseIds, setCheckedResponseIds] = useState(new Set<number>());

    const handleClickResponse = (id: number) => (e: React.MouseEvent) => {
        e.stopPropagation();
        const multiselect = e.shiftKey;

        if (checkedResponseIds.has(id)) {
            let newState = new Set(checkedResponseIds);
            if (multiselect) {
                newState.delete(id);
            } else {
                newState = new Set([]);
            }

            setCheckedResponseIds(newState);
        } else {
            let newState = new Set(checkedResponseIds);
            if (multiselect) {
                newState.add(id);
            } else {
                newState = new Set([id]);
            }

            setCheckedResponseIds(newState);
        }
    };

    const currentlyFocusedAnswer = () => {
        if (checkedResponseIds.size === 0) {
            return <div>Select a response</div>;
        }

        if (checkedResponseIds.size > 1) {
            return <div>Multiple answers selected. Select just one answer to view it</div>;
        }

        const focusedResponseId = Array.from(checkedResponseIds)[0];
        const answer = data.survey_question_responses.find((r) => r.id === focusedResponseId);

        if (!answer) {
            return <div>No answer found...</div>;
        }

        const imageUrl = `${imageRootUrl}${answer.image_url}`;

        const maybeImage = answer.image_url ? <img src={imageUrl} alt={answer.response} /> : null;

        return (
            <div>
                {maybeImage}
                {answer.response}
            </div>
        );
    };

    const handleHide = () => {
        onHide(checkedResponseIds);
    };
    const handleShow = () => {
        onShow(checkedResponseIds);
    };
    const handleHighlight = () => {
        onHighlight(checkedResponseIds);
    };

    const sortByVisibility = (a: ISurveyQuestionResponse, b: ISurveyQuestionResponse) => {
        const aVis = a.highlighted ? 1 : a.hidden ? -1 : 0;
        const bVis = b.highlighted ? 1 : b.hidden ? -1 : 0;

        if (aVis < bVis) {
            return 1;
        }

        if (aVis > bVis) {
            return -1;
        }

        return 0;
    };

    const sortedResponses = data.survey_question_responses.slice().sort(sortByVisibility);

    return (
        <Column className={styles.questionAndResponses}>
            <div className={styles.question}>
                <h2>{data.prompt}</h2>
            </div>
            <Column className={styles.authorAndAnswer}>
                <Row horizontal={"flex-start"} className={styles.contentArea}>
                    <div className={styles.authorSelection}>
                        {sortedResponses.map((r) => {
                            if (r.response === "") {
                                return null;
                            }
                            return (
                                <div
                                    className={cn(styles.authorContainer, {
                                        [styles.active]: checkedResponseIds.has(r.id),
                                        [styles.hidden]: r.hidden,
                                        [styles.highlighted]: r.highlighted,
                                    })}
                                    onClick={handleClickResponse(r.id)}
                                >
                                    <div className={cn("layout", "row")}>
                                        <Author className={styles.author} author={r.survey_responder} />
                                    </div>
                                    <div className={cn("layout", "row")}>
                                        <div className={cn("layout", "margin-right-1")}>
                                            {r.hidden ? <EyeInvisibleTwoTone /> : <EyeTwoTone />}
                                        </div>

                                        {r.highlighted ? <StarTwoTone /> : <StarOutlined />}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className={styles.focusedAnswer}>{currentlyFocusedAnswer()}</div>
                </Row>
                {checkedResponseIds.size ? (
                    <Row className={styles.curationActions}>
                        <div>Set visibility for {checkedResponseIds.size} responses:</div>
                        <div className={styles.curationActionButtons}>
                            <Button onClick={handleHide}>Hide</Button>
                            <Button onClick={handleShow}>Show</Button>
                            <Button onClick={handleHighlight}>Highlight</Button>
                        </div>
                    </Row>
                ) : null}
            </Column>
        </Column>
    );
};
