import Cookies from "universal-cookie";

import jsonToFormData from "@ajoelp/json-to-formdata";

import {
    Authorization, Curator,
    IAuthHeaders,
    INetworkCallbacks,
    IPutSurveyRequestPayload,
    ISurveySubmissionPayload
} from "../types";
import {action} from "mobx";

export const SHORT_MIN_CHAR = 10;
export const SHORT_MAX_CHAR = 1000;

export const LONG_MIN_CHAR = 100;
export const LONG_MAX_CHAR = 1000;

export class ApiStore {
    private rootPath: string;
    private authHeaders: IAuthHeaders;
    private cookieContext: Cookies;
    private networkCallbacks: INetworkCallbacks;

    constructor(rootPath: string, cookieContext: Cookies, networkCallbacks: INetworkCallbacks) {
        this.rootPath = rootPath;
        this.cookieContext = cookieContext;
        this.networkCallbacks = networkCallbacks;
        this.authHeaders = {
            "Access-Token": null,
            "Uid": null,
            "Expiry": null,
            "Client": null,
        };

        this.readAuthHeadersFromCookies();
    }

    /********************
     *
     * Utilities
     *
     *******************/


    private async sendFormData(method: string, path: string, formData: FormData): Promise<Response> {
        this.networkCallbacks.start();

        const url = `${this.rootPath}${path}`;
        const authHeaders: {[header: string]: string | null} = {};

        Object.entries(this.authHeaders)
            .filter(([_k, v]) => v !== null)
            .map(([k, v]) => authHeaders[k] = v);

        const response = await fetch(url, {
            method,
            headers: {
                'Content-Type': "multipart/form-data",
                ...authHeaders,
            },
            body: formData,
        });

        this.readAuthHeaders(response);

        this.networkCallbacks.end();

        return response;
    }


    /**
     * Fetches from API and reads/writes authorization headers
     * @param method
     * @param path
     * @param payload
     */
    private async fetch(method: string, path: string, payload?: any): Promise<Response> {
        this.networkCallbacks.start();

        const url = `${this.rootPath}${path}`;
        const authHeaders: {[header: string]: string | null} = {};

        Object.entries(this.authHeaders)
            .filter(([_k, v]) => v !== null)
            .map(([k, v]) => authHeaders[k] = v);

        console.log("fetching", method, path, payload);

        console.log("raw json", payload);
        const body = payload ? jsonToFormData(payload) : undefined;
        console.log("encoded", body);

        const response = await fetch(url, {
            method,
            headers: {
                "x": "duh",
                ...authHeaders,
            },
            body,
        });


        this.readAuthHeaders(response);

        this.networkCallbacks.end();

        return response;
    }

    /**
     * Writes an authorization header to cookies
     * @param header
     * @param value
     */
    private writeAuthHeaderToCookie(header: string, value: string | null, expiry: string | null) {
        this.cookieContext.set(header, value, {path: "/", expires: expiry ? new Date(expiry) : undefined});
    }


    /**
     * Reads auth headers from cookies
     */
    private readAuthHeadersFromCookies() {
        Object.keys(this.authHeaders).forEach(header => {
            const value = this.cookieContext.get(header);
            this.authHeaders[header] = value;
        });
    }


    /**
     * Reads authorization headers off of a given response,
     * stores in ApiStore state for next request
     * @param response
     */
    private readAuthHeaders(response: Response) {
        console.log("Saving response auth headers...");
        const expiry = response.headers.get("Expiry");
        Object.keys(this.authHeaders).forEach(header => {
            const headerValue = response.headers.get(header);
            if (headerValue != null && headerValue !== "") {
                this.authHeaders[header] = response.headers.get(header);
                this.writeAuthHeaderToCookie(header, headerValue, expiry);
            }
        });
    }

    @action.bound
    public clearUserSession() {
        Object.keys(this.authHeaders).forEach(headerName => {
            this.cookieContext.remove(headerName);
        });

        this.authHeaders = {};
    }


    /********************
     *
     * Authorization endpoints
     *
     *******************/

    /**
     * Signs in the user, accepts a callback to use for setting cookies
     * @param payload
     * @param setCookies
     */
    public async signInUser(payload: Authorization.SignInUser.RequestPayload, setCookies?: (authResponse: Response) => void): Promise<Response> {
        const [ METHOD, PATH ] = ["POST", "/auth/sign_in"];
        const response = await this.fetch(METHOD, PATH, payload);

        if (setCookies && response.ok) {
            setCookies(response);
        }

        return response;
    }

    /**
     * Registers a new user
     * @param payload
     */
    public async registerUser(payload: Authorization.RegisterUser.RequestPayload): Promise<Response> {
        const [ METHOD, PATH ] = ["POST", "/auth"];
        const response = await this.fetch(METHOD, PATH, payload);

        return response;

    }


    /********************
     *
     * Curator endpoints
     *
     *******************/

    /**
     * Creates a new Circle
     * @param name
     */
    public async createCircle(name: string) {
        const [ METHOD, PATH ] = ["POST", "/circles"];
        const response = await this.fetch(METHOD, PATH, {name});
        console.log(response);

        return response;
    }

    /***
     * Gets the Circles curated by the currently logged in user
     */
    public async getCirclesCuratorOf(): Promise<Response> {
        const [ METHOD, PATH ] = ["GET", "/circles"];
        const response = await this.fetch(METHOD, PATH);

        return response;
    }


    /**
     * Creates a new Edition
     * @param payload
     */
    public async createEdition(payload: Curator.CreateEdition.RequestPayload): Promise<Response> {
        const [ METHOD, PATH ] = ["POST", `/circles/${payload.circleId}/editions`];
        const { title } = payload;
        const response = await this.fetch(METHOD, PATH, { title });

        return response;
    }

    /**
     * Creates a new Survey
     * @param payload
     */
    public async createSurvey(payload: Curator.CreateSurvey.RequestPayload): Promise<Response> {
        const [ METHOD, PATH ] = ["POST", "/"];
        const response = await this.fetch(METHOD, PATH, payload);

        return response;
    }


    /**
     * Gets the Editions for a given Circle
     * @param id
     */
    public async getEditionsForCircle(id: number): Promise<Response> {
        const [ METHOD, PATH ] = [ "GET", `/circles/${id}/editions`];
        const response = await this.fetch(METHOD, PATH);

        return response;
    }


    /**
     * Gets the contents of an Edition; must be owner
     * @param circleId
     * @param editionId
     */
    public async getEditionPreview(circleId: number, editionId: number) {
        console.log("Fetching preview");
        const [ METHOD, PATH ] = [ "GET", `/circles/${circleId}/editions/${editionId}`];
        const response = await this.fetch(METHOD, PATH);

        return response;
    }

    /**
     * Gets the contents of an Edition; must have an edition token
     * @param circleId
     * @param editionId
     */
    public async getEdition(circleId: number, editionId: number, editionToken: string) {
        const [ METHOD, PATH ] = [ "GET", `/circles/${circleId}/editions/${editionId}?token=${editionToken}`];
        const response = await this.fetch(METHOD, PATH);

        return response;
    }

    /**
     * Updates the Survey for a given Edition
     * @param circleId
     * @param editionId
     * @param payload
     */
    public async putSurvey(circleId: number, editionId: number, payload: IPutSurveyRequestPayload) {
        const [ METHOD, PATH ] = [ "PUT", `/circles/${circleId}/editions/${editionId}/survey`];
        const response = await this.fetch(METHOD, PATH, payload);
        console.log(response);

        return response;
    }

    /**
     * Gets the survey for a given edition
     * @param circleId
     * @param editionId
     * @param surveyToken
     */
    public async getSurvey(circleId: number, editionId: number, surveyToken: string) {
        const [ METHOD, PATH ] = [ "GET", `/circles/${circleId}/editions/${editionId}?token=${surveyToken}`];
        const response = await this.fetch(METHOD, PATH);
        console.log(response);

        return response;
    }

    /**
     * Publishes a survey, returns a surveyToken
     * @param circleId
     * @param editionId
     */
    public async publishSurvey(circleId: number, editionId: number) {
        const [ METHOD, PATH ] = [ "POST", `/circles/${circleId}/editions/${editionId}/survey/publish`];
        const response = await this.fetch(METHOD, PATH);

        return response;
    }

    /**
     * Publishes a newsletter, returns a newsletterToken
     * @param circleId
     * @param editionId
     */
    public async publishNewsletter(circleId: number, editionId: number) {
        const [ METHOD, PATH ] = [ "POST", `/circles/${circleId}/editions/${editionId}/publish`];
        const response = await this.fetch(METHOD, PATH);

        return response;
    }

    /**
     * Gets all submissions for a given survey; requires the curator to be logged in
     * @param circleId
     * @param editionId
     */
    public async getSurveySubmissions(circleId: number, editionId: number) {
        const [ METHOD, PATH ] = [ "GET", `/circles/${circleId}/editions/${editionId}`];
        const response = await this.fetch(METHOD, PATH);

        return response;
    }

    /**
     * Sets the given responseIds to "hidden" visibility for publication
     * @param circleId
     * @param editionId
     * @param responseIds
     */
    public async hideResponses(circleId: number, editionId: number, responseIds: Set<number>) {
        const [ METHOD, PATH ] = [ "PUT", `/circles/${circleId}/editions/${editionId}/survey/responses/${Array.from(responseIds)[0]}`];
        const response = await this.fetch(METHOD, PATH, {highlighted: false, hidden: true});

        return response;
    }

    /**
     * Sends a survey submission
     * @param circleId
     * @param editionId
     * @param surveyToken
     * @param payload
     */
    public async postSurveySubmission(circleId: number, editionId: number, surveyToken: string, payload: ISurveySubmissionPayload) {
        const [ METHOD, PATH ] = [ "POST", `/circles/${circleId}/editions/${editionId}/survey/responses?token=${surveyToken}`];
        const response = await this.fetch(METHOD, PATH, payload);

        return response;
    }


    /**
     * Attempts to get the authHeaders from the cookies
     * Returns undefined if cookies have expired or are not present.
     */
    public async attemptSignInFromCookie(): Promise<Pick<Authorization.SignInUser.ResponsePayload, "uid"> | undefined> {
        const { Uid, Expiry } = this.authHeaders;
        const expiry = Expiry ? new Date().setTime(parseInt(Expiry)) : new Date().setTime(0);
        const now = Math.floor(new Date().valueOf() / 1000);

        console.log("checking cookie expiry", now - expiry);



        if (now > expiry) {
            console.log("expired!!");
            return undefined;
        }

        if (Uid) {
            return {
                uid: Uid,
            };
        }

        return undefined;
    }
}